import 'bootstrap/dist/css/bootstrap.min.css';
import 'lightbox2/dist/css/lightbox.css';
import './src/assets/css/layout.scss';
import './src/assets/css/layout-gb-hms.scss';
import './src/assets/css/stylesheet.css';
import './src/assets/css/font-awesome.min.css';
import './src/pages/home/home.scss';
import './src/pages/about/about.scss';
import './src/pages/contact-us/contact-us.scss';
import './src/pages/blog/blog.scss';
import './src/pages/blog/blog-details.scss';
import './src/pages/services/services.scss';
import './src/pages/trip-to-hail-himalayas/trip.scss';
import './src/pages/portfolio/project/app-details.scss';
import './src/pages/careers/careers.scss';
import './src/pages/portfolio/portfolio.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'lightbox2/dist/js/lightbox.js';
import 'animate.css/animate.css';
